<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: 'ClassManageAttendance',
    components: {
        Building
    }
}
</script>
<style lang="scss">

</style>
