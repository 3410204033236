<template>
    <!-- 考勤管理 > 考勤规则 -->
    <check-attendance-manager-scroll-wrapper></check-attendance-manager-scroll-wrapper>
</template>

<script>
import CheckAttendanceManagerScrollWrapper from '@/components/scrollWrapper/CheckAttendanceManager.vue';

export default {
    name: "CheckAttendanceRuleManager",
    components: {
        CheckAttendanceManagerScrollWrapper
    }
}
</script>
