<template>
    <!--班级管理 > 考试项目-->
    <class-manage-examination></class-manage-examination>
</template>
<script>
import ClassManageExamination from "../components/scrollWrapper/ClassManageExamination.vue";
export default {
    components: {
        ClassManageExamination,
    },
}
</script>

<style lang="scss" scoped>

</style>