<!-- 班级管理 > 班级风采 -->
<template>
    <div class="classes-mien-wrap">
        <div
            class="tabs-list"
            v-if="show"
        >
            <div
                v-for="item of tabList"
                :key="item.id"
                :class="['tabs-item', {current: item.id === activeName}]"
                v-hasPermi="item.auth"
                @click="handlerChangeTabs(item)"
            >
                {{ item.title }}
            </div>
        </div>
        <div class="classes-mien-content">
            <my-class-introduce v-if="activeName === '1' && show" />
            <my-class-dynamic v-if="activeName === '2' && show" />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    components: {
        myClassIntroduce: () => import(/* webpackChunkName:'myClassIntroduce' */"@/components/scrollWrapper/myClassIntroduce"),
        myClassDynamic: () => import(/* webpackChunkName:'myClassDynamic' */"@/components/scrollWrapper/myClassDynamic"),
    },
    data() {
        return {
            show: true,
            activeName: "1",
            tabList: [
                {
                    id: "1",
                    title: "班级介绍",
                    auth: ["classIntroduction:all"],
                },
                {
                    id: "2",
                    title: "班级动态",
                    auth: ["classDynamic:all"],
                },
            ],
        };
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    mounted() {
        this.checkauth();
    },
    methods: {
        checkauth() {
            let res = this.permissions.indexOf("classIntroduction:all");
            let res2 = this.permissions.indexOf("classDynamic:all");

            if (res !== -1) {
                this.activeName = "1";
            } else if (res2 !== -1) {
                this.activeName = "2";
            } else {
                this.show = false;
            }
        },
        handlerChangeTabs(data) {
            this.activeName = data.id;
        },
        handleClick(data) {},
    },
};
</script>
<style lang="scss" scoped>
.classes-mien-wrap {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 124px);
    box-sizing: border-box;
    padding-right: 10px;
    .tabs-list {
        display: flex;
        width: 100%;
        height: 50px;
        line-height: 50px;
        background-color: #fff;
        justify-content: center;
        border-radius: 4px;
        // border-top-left-radius: 4px;
        // border-bottom-left-radius: 4px;
        overflow: hidden;

        .tabs-item {
            width: 100px;
            position: relative;
            text-align: center;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
        }

        .tabs-item.current {
            color: #3c7fff;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                height: 3px;
                width: 30px;
                background: #3C7FFF;
                border-radius: 2px;
            }
        }
    }

    .classes-mien-content {
        flex: 1;
        overflow: hidden;
    }
}
</style>
