<template>
  <div>
    <!--班级管理-->
    <KeepAliveRouterView />
  </div>
</template>
<script>
export default {
  name: "ClassManage"
}
</script>
