<template>
  <!--班级管理 > 我的学生-->
  <!-- <div class="reso-set">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-hasPermi="['myStudentBase:all']" label="基础信息" name="first">
        <class-manage-my-student-base />
      </el-tab-pane> -->
      <!-- <el-tab-pane label="学生量化" name="second">
      </el-tab-pane>
      <el-tab-pane label="德育档案" name="3">
      </el-tab-pane>
      <el-tab-pane label="教师评语" name="4">
      </el-tab-pane> -->
    <!-- </el-tabs>
  </div> -->


  <div> 
    <!-- tabs v-hasPermi="item.auth"-->
    <div class="tabs-list">
      <div :class="['tabs-item']" v-hasPermi="['myStudentBase:all']"  @click="handlerChangeTabs" >
        基础信息
        <span class="line" ></span>
      </div>
    </div>

    <!-- content -->
    <div>
      <class-manage-my-student-base  />
    </div>
  </div>



</template>
<script>
import ClassManageMyStudentBase from '@/components/scrollWrapper/ClassManageMyStudentBase.vue';
export default {
  components: {
    ClassManageMyStudentBase,
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handlerChangeTabs(){

    },
    handleClick(tab, event) {

    }
  }
}
</script>
<style lang="scss" scoped>
  .tabs-list {
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #fff;

    .tabs-item {
      width: 100px;
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
    }

    .tabs-item.current {
      color:#409eff ;
    }

    .line {
      position: absolute;
      bottom: 2px;
      left: 25%;
      width: 50%;
      height: 2px;
      background-color: #409eff;

    }
  } 
</style>
