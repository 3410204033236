<!--
 * @Author: hanjunmei 820448229@qq.com
 * @Date: 2024-07-17 08:59:00
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2024-08-02 15:47:59
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\ClassManageAnalysis.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!--班级管理 > 成绩分析-->
    <div class="all-document">
        <component
          @change="change"
          :is="curCom"
          :mode="mode"
          :id="id"
        ></component>
    </div>
</template>
<script>
export default {
    components: {
        ClassManageAnalysis: () => import("@/components/scrollWrapper/ClassManageAnalysis"),
        ClassManageAnalysisDetails: () => import("@/components/scrollWrapper/ClassManageAnalysisDetails"),
    },
    data () {
        return {
            // list展示列表页面；feedback展示反馈页
            mode: "list",
            id: '',
            show: true,
            curCom: 'ClassManageAnalysis',
            comps: [
              'ClassManageAnalysis',
              'ClassManageAnalysisDetails'
            ],
        };
    },
    mounted () {
        this.change(this.mode)
    },
    methods: {
        change (mode, id) {
            console.log(mode,id,'==== ')
            this.mode = mode;
            this.id = id
            if (this.mode === 'list') {
                this.curCom = 'ClassManageAnalysis'
                this.setGlobalPageType('list')
            } else {
                this.curCom='ClassManageAnalysisDetails'
                this.setGlobalPageType('detail')
            }
        },
        setGlobalPageType (type) {
            this.mode = type
            if(type === 'list'){
                this.curCom = 'ClassManageAnalysis'
            }else{
                this.curCom='ClassManageAnalysisDetails'
            }
            this.$eventDispatch('setGlobalPageType', type)
        },
    },

}
</script>
<style lang="scss" scoped>
.all-document {
    display: flex;
    flex-direction: column;
    // height: 100%;
    .all-document {
        flex: 1;
    }
}
</style>
