<template>
  <!--班级管理 > 班级量化-->
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "ClassManageQuantification",
        components: {
            Building
        }
    }
</script>
<style lang="scss">

</style>
